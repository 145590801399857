.btn-primary {
    color: #ffffff;
    background-color: #6EAC24;
    border-color: #4E7B18;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #6EAC24;
    border-color: #4E7B18;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #ffffff;
    background-color: #4E7B18;
    border-color: #4E7B18;
}

